import Swiper from 'swiper';
import { Manipulation,Navigation,Autoplay} from 'swiper/modules';
export default () => {

    const sliders = document.querySelectorAll('.product-carousel');

    if (!sliders.length) {
        return;
    }

    sliders.forEach(function (slider, index) {

        const allSlides = slider.querySelectorAll('.swiper-slide');
        const centerSlides = slider.classList.contains('swiper-slides-centered');

        if (!allSlides.length) {
            return;
        }

        const slides = [];

        const productSwiperOpts = {
            modules: [Navigation,Manipulation],
            grabCursor: true,
            simulateTouch:true,
            loop: false,
            speed: 600,
            centeredSlides: false,
            centerInsufficientSlides: centerSlides,
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 24,
            slideToClickedSlide: false,
            watchSlidesProgress: true,
            autoHeight: false,
            init: false,
            navigation: {
                nextEl: slider.querySelector('.next-slide'),
                prevEl: slider.querySelector('.prev-slide'),
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                }
            }
        };

        const productSwiper = new Swiper( slider.querySelector('.swiper'), productSwiperOpts);

        let filterResetLink = slider.querySelector('a.filter-category-all');
        let filterLinks = slider.querySelectorAll('a.filter-category');

        productSwiper.init();

        filterLinks.forEach((filterLink, index) => {

            let slideCategory = filterLink.dataset.filter;

            let categorySlides = [...allSlides].filter(function(el) {
                let allCats =  el.dataset.category.split('|');
                return el.dataset.category.includes(slideCategory);
            });

            slides[slideCategory] = categorySlides;

            filterLink.addEventListener('click', function(e) {

                e.preventDefault();
                clearSlides();

                this.classList.add('active');

                let filter = this.dataset.filter;
                let filteredSlides = slides[filter];
                
                productSwiper.addSlide(1, filteredSlides);
                productSwiper.slideTo(0);
            
                productSwiper.wrapperEl.classList.remove('fade-out');
                productSwiper.wrapperEl.classList.add('fade-in');

            }, false);



        });

        if (filterResetLink) {
            filterResetLink.addEventListener('click', function(e) {

                e.preventDefault();
                clearSlides();
                productSwiper.addSlide(1, allSlides);
                productSwiper.slideTo(0);
                this.classList.add('active');
                productSwiper.wrapperEl.classList.remove('fade-out');
                productSwiper.wrapperEl.classList.add('fade-in');

            }, false);
        }

        function clearSlides() {

            productSwiper.wrapperEl.classList.remove('fade-in');
            productSwiper.wrapperEl.classList.add('fade-out');
            productSwiper.removeAllSlides();

            let slideFilters = document.querySelectorAll('.slide-filters a');
            slideFilters.forEach(function(slideFilter) {
                slideFilter.classList.remove('active');
            });

        }

    });

}