// Required for dev hot reload

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

import {stickyHeader} from './modules/stickyHeader';
stickyHeader();

import initMegaMenu from './modules/megaMenu';
document.addEventListener('DOMContentLoaded', initMegaMenu, false);


import initMobileMenu from './modules/mobileMenu';
document.addEventListener('DOMContentLoaded', initMobileMenu, false);

import smoothScroll from './modules/smoothScroll';
document.addEventListener('DOMContentLoaded', smoothScroll, false);

import initProductSwiper from './modules/productSwiper';
document.addEventListener('DOMContentLoaded', initProductSwiper, false);

import initGallerySwiper from './modules/gallerySwiper';
document.addEventListener('DOMContentLoaded', initGallerySwiper, false);

import initModals from './modules/galleryModalSwiper';
document.addEventListener('DOMContentLoaded', initModals, false);

import initThreeUpSwiper from './modules/threeUpSwiper';
document.addEventListener('DOMContentLoaded', initThreeUpSwiper, false);

import initScrollDrag from './modules/scrollDrag';
document.addEventListener('DOMContentLoaded', initScrollDrag, false);

import initAutoHeight from './modules/autoHeight';
document.addEventListener('DOMContentLoaded', initAutoHeight, false);


import initGalleryHotspots from './modules/galleryHotspots';
document.addEventListener('DOMContentLoaded', initGalleryHotspots, false);

import {shareLinks} from "./modules/shareLinks";
shareLinks();

import {quickViewModal} from './modules/quickviewModal';
quickViewModal();

import {requestQuoteModal} from './modules/requestQuoteModal';
requestQuoteModal();

import './alpine/index';
import '../css/app.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'virtual:svg-icons-register';