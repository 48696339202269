export default () => {

  const draggable = document.querySelector('.draggable');
  if (!draggable) {
    return;
  }

  let isDown = false;
  let isDrag = false;
  let startX;
  let scrollLeft;

  draggable.addEventListener('mousedown', (e) => {
    isDown = true;
    isDrag = false;
    draggable.classList.add('active');
    startX = e.pageX - draggable.offsetLeft;
    scrollLeft = draggable.scrollLeft;
    cancelMomentumTracking();
  });

  draggable.addEventListener('click', (e) => {
    if (isDrag) {
      e.preventDefault();
    }
  });


  draggable.addEventListener('mouseleave', () => {
    isDown = false;
    draggable.classList.remove('active');
  });


  draggable.addEventListener('mouseup', () => {
    isDown = false;
    draggable.classList.remove('active');
    beginMomentumTracking();
  });


  draggable.addEventListener('mousemove', (e) => {
    isDrag = true;
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - draggable.offsetLeft;
    const walk = (x - startX) * 1.5; //scroll-fast
    var prevScrollLeft = draggable.scrollLeft;
    draggable.scrollLeft = scrollLeft - walk;
    velX = draggable.scrollLeft - prevScrollLeft;
  });

  // Momentum 

  var velX = 0;
  var momentumID;

  draggable.addEventListener('wheel', (e) => {
    cancelMomentumTracking();
  }, {passive: true});

  function beginMomentumTracking(){
    cancelMomentumTracking();
    momentumID = requestAnimationFrame(momentumLoop);
  }
  function cancelMomentumTracking(){
    cancelAnimationFrame(momentumID);
  }
  function momentumLoop(){
    draggable.scrollLeft += velX;
    velX *= 0.95; 
    if (Math.abs(velX) > 0.5){
      momentumID = requestAnimationFrame(momentumLoop);
    }
  }

}