export const stickyHeader = () => {

    let main = document.getElementById("main");

    if (!main) {
        return;
    }

    let stuck = false;
    let header = document.getElementById("header");
    let stickPoint = getDistance(header);


    function getDistance(el) {
        return el.offsetTop;
    }

    window.onscroll = function(e) {

        let distanceMain = getDistance(main) - window.scrollY;

        let distance = getDistance(header) - window.scrollY;
        let offset = window.scrollY;

        if ( (distanceMain <= 0) && !stuck) {

            stuck = true;
            document.body.classList.add("fixed-header");

        } else if (stuck && (offset <= stickPoint)){

            stuck = false;
            document.body.classList.remove("fixed-header");


        }

    }

}