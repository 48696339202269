import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
gsap.registerPlugin(ScrollToPlugin);

// a.scrollto href=#element
export default () => {
    document.querySelectorAll(".scrollto").forEach((btn, index) => {
        btn.addEventListener("click", (event) => {
            let selector = btn.getAttribute('href');
            let toEl = document.querySelector(selector);
            let scrollOffset = btn.dataset.scrollOffset ?? 145;
            event.preventDefault();
            gsap.to(window, {
                duration: 0.5,
                scrollTo:{
                    y:toEl,
                    offsetY: scrollOffset
                }
            });
        });
    });
}