
export default () => {

    let header     = document.getElementById('header');
    let menuToggle = document.getElementById('togglemenu');
    let mobileMenu = document.getElementById('mobilemenu');
    let root = document.documentElement;

    function disableMenu() {
        root.classList.remove('no-scroll');
        mobileMenu.classList.remove('active');
    }

    window.addEventListener("resize", function(){
        if (root.classList.contains('no-scroll')) {
            disableMenu();
        }
    });

    menuToggle.addEventListener('click', function(event) {


        let isOpen = root.classList.contains('no-scroll');

        if (isOpen) {

            disableMenu();

        } else {
            root.classList.add('no-scroll');
            mobileMenu.classList.add('active');
            document.body.classList.add('fixed-header');
            header.classList.remove('is-hidden');
        }
        event.preventDefault();


        let topLevels = document.querySelectorAll('#mobilemenu li.parent');
        topLevels.forEach(function (parent) {

            let parentLink = parent.querySelector('a');

            parentLink.addEventListener('click', function(event) {
                topLevels.forEach((el, i) => {
                    el.classList.remove('active');
                });
                parent.classList.add('active');
            });

            onmouseover
        });


    });
}