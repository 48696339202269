import Gmodal from 'glory-modal';
import Swiper from 'swiper';
import { Manipulation,Navigation } from 'swiper/modules';
import {shareLinks} from "./shareLinks";
import initGalleryHotspots from './galleryHotspots';

export default () => {

    let selector = '#gallerymodal';
    if (!document.querySelector(selector)) {
        return;
    }


    let triggers = document.querySelectorAll('[data-modal="'+selector+'"]');
    let slideTo = 0;
    let modal;


    triggers.forEach(function (trigger) {

        trigger.addEventListener('click', function(event) {

            modal.open();
            event.preventDefault();
            slideTo = trigger.getAttribute('data-slide-index');

        });

    });


    let gmodalConfig = {
        stickySelectors: [],
        animation: true,
        backdrop: true,
        closeBackdrop: true,
        keyboard: true
    }

    modal = new Gmodal(selector, gmodalConfig);
    let elem = document.querySelector(selector);


    elem.addEventListener('gmodal:open', function(evt) {

        let swiperClone = document.querySelector('.gallery-carousel .swiper');
        let clone = swiperClone.cloneNode(true);

        let modalbody = document.querySelector('#gallerymodal .gmodal__body');
        modalbody.append(clone);


        const gallerySwiperOpts = {
            modules: [Manipulation,Navigation],
            grabCursor: true,
            simulateTouch:true,
            loop: false,
            speed: 600,
            centeredSlides: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            slideToClickedSlide: true,
            watchSlidesProgress: false,
            autoHeight: true,
            init: false,
            navigation: {
                nextEl: '#gallerymodal .gallery-next-slide',
                prevEl: '#gallerymodal .gallery-prev-slide',
            },
        };

        const gallerySwiper = new Swiper(clone, gallerySwiperOpts);

        gallerySwiper.init().slideTo(slideTo, 0, false);

        initGalleryHotspots();
        shareLinks();


    });

    elem.addEventListener('gmodal:beforeclose', function(event) {
        let modalSwiper = document.querySelector('#gallerymodal .swiper');
        modalSwiper.parentNode.removeChild(modalSwiper);
    });


}