
export const shareLinks = () => {


    let shareToggleLinks = document.querySelectorAll('.toggle-share-links');
    if (!shareToggleLinks.length) {
        return;
    }

    shareToggleLinks.forEach(function (shareToggle) {

        shareToggle.addEventListener('click', function(event) {

            event.preventDefault();

            let shareLinksContainer = this.parentNode;
            let shareLinks = shareLinksContainer.querySelector('.share-links');
            this.classList.toggle('active');

            if (!shareLinks.classList.contains('active')) {

                shareLinks.classList.add('active');
                shareLinks.style.width = 'auto';

                let width = shareLinks.clientWidth + 'px';

                shareLinks.style.width = '0px';

                setTimeout(function () {
                    shareLinks.style.width = width;
                }, 0);

            } else {

                shareLinks.style.width = '0px';

                shareLinks.addEventListener('transitionend', function () {
                    shareLinks.classList.remove('active');
                }, {
                    once: true
                });

            }

        });

    });



}