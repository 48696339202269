import Swiper from 'swiper';
import {Autoplay, EffectFade, Pagination} from 'swiper/modules';

export default () => {

    const allSlides = document.querySelectorAll('.three-up-swiper .swiper-slide');

    if (!allSlides.length) {
        return;
    }

    const slides = [];


    const threeUpSwiperOpts = {
        modules: [Pagination,EffectFade,Autoplay],
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 2500,
        },
        grabCursor: false,
        simulateTouch:true,
        loop: true,
        speed: 750,
        centeredSlides: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        slideToClickedSlide: true,
        watchSlidesProgress: true,
        autoHeight: false,
        init: false,
        pagination: {
            el: '.three-up-swiper .custom-bullet-navigation',
            clickable: true,
            type: 'bullets',
            bulletClass: 'custom-bullet',
            bulletActiveClass: 'custom-bullet-active',
        },        
        breakpoints: {
            768: {
                slidesPerView: 1,
                spaceBetween: 36
            }
        }
    };

    const threeUpSwiper = new Swiper('.three-up-swiper', threeUpSwiperOpts);

    threeUpSwiper.init();


}