import tippy from 'tippy.js';
import '../../css/partials/tippy-animation.css';

export default () => {
    tippy('.hotspot', {
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        placement: 'auto',
        allowHTML: true,
        interactive: true,
        touch: 'hold',
        animation:'shift-toward-subtle',
    });
}