// import hoverintent from 'hoverintent';

export default () => {
    
    // all top level list items
    const topLevelMenuItems = document.querySelectorAll('ul.mainmenu > li');
    // the list item containing the mega menu
    const toggleParent = document.querySelector('li.megamenu-item');
    // the toggle link for the mega menu
    const toggleEl = document.querySelector('a.products-nav-toggle');
    // the mega menu itself
    const megaMenuEl = document.querySelector('.products-nav-submenu');
    // hover timer
    let hoverInterval;

    // toggleEl always toggles the mega menu
    toggleEl.addEventListener('click', function(e){
        toggleParent.classList.toggle('hover')
        e.preventDefault();
    });

    // utility to check if any submenu is hovered
    const isHovered = function() {
        return document.querySelector(".products-nav-submenu:hover") != null || document.querySelector("a.products-nav-toggle:hover") != null;
    }

    // update the hoverInterval timer anytime a submenu is hovered
    const hideMenu = function() {
        hoverInterval = window.setTimeout( 
        function() {
            if (!isHovered()) {
                toggleParent.classList.remove('hover');
            }
        }, 500);
    }

    // clear the hoverInterval timer on any submenu mouseleave
    megaMenuEl.onmouseleave = function(event) {
        clearTimeout( hoverInterval )
        hideMenu();
    }
    
    // extend the timeout for the mega menu
    topLevelMenuItems.forEach(function (el,index) {
        const exclude = el.classList.contains("megamenu-item");
        if (!exclude) {
            el.addEventListener("mouseover", hideMenu, false);
        }
    });
   
}