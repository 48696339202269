import Swiper from 'swiper';
import {Manipulation, Navigation} from 'swiper/modules';

export default () => {

    const allSlides = document.querySelectorAll('.gallery-carousel .swiper-slide');

    if (!allSlides.length) {
        return;
    }

    const slides = [];


    const gallerySwiperOpts = {
        modules: [Manipulation,Navigation],
        grabCursor: true,
        simulateTouch:true,
        loop: false,
        speed: 600,
        centeredSlides: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 12,
        watchSlidesProgress: true,
        autoHeight: false,
        init: false,
        navigation: {
            nextEl: '.gallery-carousel .next-slide',
            prevEl: '.gallery-carousel .prev-slide',
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
                spaceBetween: 24
            }
        }
    };

    const gallerySwiper = new Swiper('.gallery-carousel .swiper', gallerySwiperOpts);

    gallerySwiper.init();

    let filterResetLink = document.querySelector('.gallery-carousel a.filter-category-all');
    let filterLinks = document.querySelectorAll('.gallery-carousel a.filter-category');

    filterLinks.forEach((filterLink, index) => {

        let slideCategory = filterLink.dataset.filterCategory;

        allSlides[slideCategory] = [...allSlides].filter(function (el) {
            return el.dataset.category.includes(slideCategory);
        });

        filterLink.addEventListener('click', function(e) {

            e.preventDefault();
            clearSlides();

            this.classList.add('active');

            let filter = this.dataset.filterCategory;
            let filteredSlides = allSlides[filter];

            gallerySwiper.addSlide(1, filteredSlides);
            gallerySwiper.slideTo(0);
            //
            gallerySwiper.wrapperEl.classList.remove('fade-out');
            gallerySwiper.wrapperEl.classList.add('fade-in');

        }, false);

    });

    if (filterResetLink) {
        filterResetLink.addEventListener('click', function(e) {

            e.preventDefault();
            clearSlides();
            gallerySwiper.addSlide(1, allSlides);
            gallerySwiper.slideTo(0);
            this.classList.add('active');
            gallerySwiper.wrapperEl.classList.remove('fade-out');
            gallerySwiper.wrapperEl.classList.add('fade-in');

        }, false);
    }

    function clearSlides() {

        gallerySwiper.wrapperEl.classList.remove('fade-in');
        gallerySwiper.wrapperEl.classList.add('fade-out');
        gallerySwiper.removeAllSlides();

        let slideFilters = document.querySelectorAll('.gallery-carousel .slide-filters a');
        slideFilters.forEach(function(slideFilter) {
            slideFilter.classList.remove('active');
        });

    }


    const progressBar = document.querySelector('.gallery-carousel #progress');
    if (progressBar) {
        gallerySwiper.on('progress', function (swiper,progress) {
            let progressPct = progress * 100 + '%';
            progressBar.style.width = progressPct;
        });
    }

}